import { call, select } from 'redux-saga/effects';
import { initDev } from '@wxu/browser-dev-tools';
import { createStore } from '@wxu/redux-create-store';
import { ModuleInterface } from '@wxu/module-interface';
import { transformedPageConfigSelector } from '@wxu/page-config/src/selectors';
import { loadContexts, rehydrateSaga } from '@wxu/pc-to-html/src/sagas/web';
import { REDUCER_KEY as pageReducerKey } from '@wxu/contexts/src/page/constants';
import { reducer as pageReducer } from '@wxu/contexts/src/page/reducer';
import { createLogger } from '@wxu/logger';
import { addEventListeners } from '@wxu/scroll-to-anchor';
import { removeUnitQueries } from '@wxu/units';
import { sendReumEvent } from '@wxu/contexts/src/reum/saga';

const logger = createLogger('html-rehydrator');

/**
 * The client's entry point.
 * Rehydrates state, loads contexts, and rehydrates modules.
 * @param {Object} args
 * @param {Object} args.modules
 * @param {Function[]} [args.reduxMiddleware]
 * @param {Function[]} [args.reduxEnhancers]
 * @param {boolean} [args.useReduxHistory]
 */
export async function main({
  modules,
  reduxMiddleware = [],
  reduxEnhancers = [],
  useReduxHistory = false,
  absolutePublicPath = false,
}) {
  if (absolutePublicPath) {
    setWebpackAbsolutePublicPath();
  }

  removeQueryParams();
  await initDev();

  let history = null;

  if (useReduxHistory) {
    const { createBrowserHistory } = await import(/* webpackChunkName: "history" */'history');

    history = createBrowserHistory();
  }

  const store = await createStore(window.__data, {
    middleware: reduxMiddleware,
    enhancers: reduxEnhancers,
    history,
    baseReducers: {
      [pageReducerKey]: pageReducer,
    },
  });
  const moduleInterface = new ModuleInterface({ store });

  await moduleInterface.runSaga(rehydrate, modules, moduleInterface);

  // If enabled, sync history with redux, after rehydration
  if (useReduxHistory) {
    const { syncHistoryWithStore } = await import(/* webpackChunkName: "reduxHistory" */'@wxu/redux-history');

    history = syncHistoryWithStore({ store, history });
  }
}

/**
 * The main saga to run, intended for the client-side entry script.
 * Loads contexts and rehydrates modules.
 * @param {Object} modules
 * @param {ModuleInterface} moduleInterface
 */
export function* rehydrate(modules, moduleInterface) {
  try {
    const pageConfig = yield select(transformedPageConfigSelector);
    const deviceClass = pageConfig?.meta?.deviceClass;

    if (deviceClass !== 'desktop') {
      yield call(addEventListeners);
    }
    yield call(sendReumEvent, 'loadContextsStart');
    yield call(loadContexts, moduleInterface);
    yield call(sendReumEvent, 'rehydrateStart');
    yield call(rehydrateSaga, modules, moduleInterface, pageConfig);
  } catch (err) {
    logger.error(err, 'Failed to rehydrate');
    throw err;
  }
}

/**
 * Removes query parameters we don't want sticking around.
 */
function removeQueryParams() {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);

  removeUnitQueries(searchParams);

  const search = searchParams.toString();

  url.search = search ? `?${search}` : '';
  window.history.replaceState(null, document.title, url.toString());
}

/**
 * Sets webpack assets public path on the fly, to an absolute path.
 * Needed for cross origin requests.
 *
 * Consumer needs to set window.__wxuAssetsPublicPathDomain.
 *
 * Set public path on the fly doc: https://webpack.js.org/guides/public-path/#on-the-fly
 */
function setWebpackAbsolutePublicPath() {
  if (window.__wxuAssetsPublicPathDomain) {
    // eslint-disable-next-line camelcase
    __webpack_public_path__ = `${window.__wxuAssetsPublicPathDomain}${__webpack_public_path__}`;
  }
}
