import {
  CA,
  EXEMPT,
  GDPR,
  LATAM_CO,
  LATAM_DO,
  LATAM_PE,
  LGPD,
  PIPL,
  TR_KVKK,
  USA,
  USA_CCPA,
  USA_CO,
  USA_CT,
  USA_VA,
  Regime,
  RESTRICTIVE_REGIMES,
} from './regimesConfig';
import {
  ESSENTIAL_TECHNOLOGY,
  INTERNATIONAL_TRANSFER,
  FUNCTIONAL_TECHNOLOGY,
  GEOGRAPHICALLY_RELEVANT_ADVERTISING,
  SALE_OF_DATA,
  Purpose,
} from './purposesConfig';

export interface FeatureConfig {
  name: string;
  blockRegimes: Regime[];
  enableRegimes: Regime[];
  honorUserChoicesOverRegimeDefaults: boolean;
  honorOtherRegimesOptOut: boolean;
  honorOtherRegimesOptIn: boolean;
  purposes: Purpose[];
}

export interface FeatureFlags {
  [featureConfigName: string]: boolean;
}

export const featureConfigs: ReadonlyArray<FeatureConfig> = [
  {
    name: 'airlyticsFullMetrics',
    blockRegimes: [],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [FUNCTIONAL_TECHNOLOGY],
  },
  {
    name: 'amazon',
    blockRegimes: [...RESTRICTIVE_REGIMES],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [SALE_OF_DATA],
  },
  {
    name: 'amplitudeFullMetrics',
    blockRegimes: [],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [FUNCTIONAL_TECHNOLOGY],
  },
  {
    name: 'burdaAdEngine',
    blockRegimes: [],
    enableRegimes: [CA, EXEMPT, GDPR, LATAM_CO, LATAM_DO, LATAM_PE, LGPD, PIPL, TR_KVKK, USA, USA_CCPA, USA_CO, USA_CT, USA_VA],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [ESSENTIAL_TECHNOLOGY, INTERNATIONAL_TRANSFER],
  },
  {
    name: 'burdaHeaderBidders',
    blockRegimes: [],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [GEOGRAPHICALLY_RELEVANT_ADVERTISING, SALE_OF_DATA],
  },
  {
    name: 'comscore',
    blockRegimes: [PIPL],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [], // IAB Vendor, remove config if needed
  },
  {
    name: 'criteoSlot',
    blockRegimes: [...RESTRICTIVE_REGIMES],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [SALE_OF_DATA],
  },
  {
    name: 'custParamsPii',
    blockRegimes: [],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [GEOGRAPHICALLY_RELEVANT_ADVERTISING, SALE_OF_DATA],
  },
  {
    name: 'experiment',
    blockRegimes: [],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [FUNCTIONAL_TECHNOLOGY],
  },
  {
    name: 'facebookWxNode',
    blockRegimes: [],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [FUNCTIONAL_TECHNOLOGY],
  },
  {
    name: 'findMe',
    blockRegimes: [],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [GEOGRAPHICALLY_RELEVANT_ADVERTISING],
  },
  {
    name: 'geoIP',
    blockRegimes: [],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [GEOGRAPHICALLY_RELEVANT_ADVERTISING],
  },
  {
    name: 'googleNonLimitedAds',
    blockRegimes: [],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [FUNCTIONAL_TECHNOLOGY, GEOGRAPHICALLY_RELEVANT_ADVERTISING],
  },
  {
    name: 'gpt',
    blockRegimes: [],
    enableRegimes: [CA, EXEMPT, GDPR, LATAM_CO, LATAM_DO, LATAM_PE, LGPD, PIPL, TR_KVKK, USA, USA_CCPA, USA_CO, USA_CT, USA_VA],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [ESSENTIAL_TECHNOLOGY, INTERNATIONAL_TRANSFER],
  },
  {
    name: 'gptPiiGDPR',
    blockRegimes: [...RESTRICTIVE_REGIMES],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [],
  },
  {
    name: 'gptPiiSaleUSA',
    blockRegimes: [],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [SALE_OF_DATA],
  },
  {
    name: 'gptPrisma',
    blockRegimes: [],
    enableRegimes: [CA, EXEMPT, ...RESTRICTIVE_REGIMES, USA, USA_CCPA, USA_CO, USA_CT, USA_VA],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [ESSENTIAL_TECHNOLOGY, INTERNATIONAL_TRANSFER],
  },
  {
    name: 'headerBidders',
    blockRegimes: [],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [GEOGRAPHICALLY_RELEVANT_ADVERTISING, SALE_OF_DATA],
  },
  {
    name: 'ias',
    blockRegimes: [],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [SALE_OF_DATA],
  },
  {
    name: 'indexExchange',
    blockRegimes: [CA, EXEMPT, ...RESTRICTIVE_REGIMES, USA, USA_CCPA, USA_CO, USA_CT, USA_VA],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [SALE_OF_DATA],
  },
  {
    name: 'instagramWxNode',
    blockRegimes: [],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [FUNCTIONAL_TECHNOLOGY],
  },
  {
    name: 'lotame',
    blockRegimes: [CA, EXEMPT, ...RESTRICTIVE_REGIMES],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [SALE_OF_DATA],
  },
  {
    name: 'medallia',
    blockRegimes: [],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [FUNCTIONAL_TECHNOLOGY],
  },
  {
    name: 'mParticleFullMetrics',
    blockRegimes: [],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [FUNCTIONAL_TECHNOLOGY],
  },
  {
    name: 'medallia',
    blockRegimes: [],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [FUNCTIONAL_TECHNOLOGY],
  },
  {
    name: 'nielsen',
    blockRegimes: [],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [], // IAB Vendor
  },
  {
    name: 'outbrain',
    blockRegimes: [],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [SALE_OF_DATA], // IAB Vendor
  },
  {
    name: 'rubicon',
    // Don't add regimes to block here because amazon and criteoSlot
    // are dependent on rubicon.
    // Also calling rubicon's requestBids is already blocked in
    // `contexts/src/moneytree/thirdParties/rubicon/parallel.bids.js`
    blockRegimes: [],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [SALE_OF_DATA],
  },
  {
    name: 'socialLinksFooter',
    blockRegimes: [...RESTRICTIVE_REGIMES],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [],
  },
  {
    name: 'taboola',
    blockRegimes: [],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [SALE_OF_DATA], // IAB vendor
  },
  {
    name: 'twitterWxNode',
    blockRegimes: [],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [FUNCTIONAL_TECHNOLOGY],
  },
  {
    name: 'youtubeWxNode',
    blockRegimes: [],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [FUNCTIONAL_TECHNOLOGY],
  },
  {
    name: 'wfxTriggers',
    blockRegimes: [],
    enableRegimes: [],
    honorUserChoicesOverRegimeDefaults: true,
    honorOtherRegimesOptOut: true,
    honorOtherRegimesOptIn: true,
    purposes: [GEOGRAPHICALLY_RELEVANT_ADVERTISING, SALE_OF_DATA],
  },
];
