import { getEnv } from '@wxu/env';
import { createLogger } from '@wxu/logger';

const logger = createLogger('new-relic');

export type ActionName = string;
export type AttributeName = string;
export type AttributeValue = string | number | boolean | null;
export type AttributeMap = Record<AttributeName, AttributeValue>;
declare global {
  interface Window {
    // Add type to window base on the OTEL provider
    NREUM: {
      addPageAction: (name: ActionName, attributes?: AttributeMap) => void;
      setCustomAttribute: (name: AttributeName, value: AttributeValue, persist?: boolean) => void;
    };
  }
}

export const metaPrefix = 'meta.';
export const perfPrefix = 'perf.';

export enum OTELScript {
  provider = 'new-relic-script',
}

export enum OTELGlobalMethod {
  method = 'NREUM',
}

function getEnvironment() {
  let environment = 'dev';

  if (getEnv('WEBCAKES_ENV') === 'PROD') environment = 'prod';

  if (getEnv('WEBCAKES_ENV') === 'PERF') environment = 'perf';

  if (getEnv('WEBCAKES_ENV') === 'STAGE') environment = 'stage';

  return environment;
}

export async function getOtelProviderScriptSrc() {
  const environment = getEnvironment();
  const { default: newRelicScriptAssetSrc } = await import(
    `!!html-loader!./assets/${OTELScript.provider}.${environment}.ext.html`
  );

  return newRelicScriptAssetSrc;
}

export function setReumMeta(name: AttributeName, value: AttributeValue) {
  try {
    window?.[OTELGlobalMethod.method]?.setCustomAttribute?.(`${metaPrefix}${name}`, value);
  } catch (e) {
    //
  }
}
export function sendReumEvent(event: PerformanceEntry | string, meta = {}) {
  try {
    let name: string;
    const perf: AttributeMap = {};

    if (typeof event === 'string') {
      // if we are given a string (event name) instead of a performance entry
      // then use the current timestamp/timeSinceLoad set by NREUM
      name = event;
    } else {
      // otherwise, add a custom timestamp/duration (NREUM does not allow the
      // primary timestamp/timeSinceLoad to be overridden)
      name = event.name;
      if (event.entryType !== 'mark') {
        perf.duration = ms2s(event.duration);
        perf.timestamp = ms2s(window.performance.timeOrigin + event.startTime);
      } else {
        perf.duration = ms2s(event.startTime);
        perf.timestamp = ms2s(window.performance.timeOrigin);
      }
    }
    window?.[OTELGlobalMethod.method]?.addPageAction?.(name, {
      ...prefixKeys(perf, perfPrefix),
      ...prefixKeys(meta, metaPrefix),
    });
  } catch (e) {
    logger.error(e, 'sendReumEvent error');
  }
}
function prefixKeys(obj: AttributeMap, prefix = ''): AttributeMap {
  return Object.fromEntries(
    Object.entries(obj).map(([key]) => [`${prefix}${key}`, obj[key]])
  );
}
// milliseconds to seconds
function ms2s(milliseconds: number): number {
  return milliseconds / 1000;
}

export function serverTimingMarkup(): string {
  return `<script>
  try {
    performance.getEntries().filter(function(e){return e.entryType==='navigation';})[0].serverTiming.forEach(function(t){
      if (t.name==='cdn-cache')  window.NREUM.setCustomAttribute('meta.cdnCache',t.description);
      if (t.name==='edge') return window.NREUM.setCustomAttribute('meta.cdnEdge',t.duration);
      if (t.name==='origin') return window.NREUM.setCustomAttribute('meta.cdnOrigin',t.duration);
    });
  } catch (e) {
  }
</script>`;
}
